<template>
    <div class="construction_matching" v-loading="isLoading">
        <div class="content_t">
            <div class="header_wrapper el_left">
                <h4 class="head el_left">
                    生产任务信息<strong>（{{ task_number }}）</strong>
                </h4>
                <el-button
                    type="primary"
                    size="mini"
                    class="save el_right"
                    @click="saveProductFormula"
                    v-if="isReadOnly == false"
                    :disabled="editReadOnly"
                >
                    保存
                </el-button>
                <table>
                    <tbody>
                        <tr>
                            <th>工程名称：</th>
                            <td>
                                <el-input disabled v-model="productFormula.project_name" placeholder="请输入内容"></el-input>
                            </td>
                            <th>工程部位:</th>
                            <td>
                                <el-input disabled v-model="productFormula.place_detail" placeholder="请输入内容"></el-input>
                            </td>
                            <th>产品标号:</th>
                            <td>
                                <el-input disabled v-model="productFormula.mark_number" placeholder="请输入内容"></el-input>
                                <input type="hidden" name="" v-model="productFormula.strength_grade">
                            </td>
                        </tr>
                        <tr>
                            <th>坍落度:</th>
                            <td>
                                <el-input disabled v-model="productFormula.slump" placeholder="请输入内容"></el-input>
                            </td>
                            <th>浇筑方式:</th>
                            <td>
                                <el-input disabled v-model="productFormula.pouring_name" placeholder="请输入内容"></el-input>
                                <input type="hidden" name="" v-model="productFormula.pouring">
                            </td>
                            <th>是否出资料：</th>
                            <td>
                                <el-radio-group v-model="productFormula.is_datum" :disabled="editReadOnly">
                                    <el-radio :label="true" :disabled="isReadOnly">
                                        是
                                    </el-radio>
                                    <el-radio :label="false" :disabled="isReadOnly">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                        </tr>
                        <tr>
                            <th>产生抗压试验：</th>
                            <td>
                                <el-radio-group v-model="productFormula.is_testblock" :disabled="editReadOnly">
                                    <el-radio :label="true" :disabled="isReadOnly">
                                        是
                                    </el-radio>
                                    <el-radio :label="false" :disabled="isReadOnly">
                                        否
                                    </el-radio>
                                </el-radio-group>
                            </td>
                            <th>试块组数:</th>
                            <td>
                                <el-input
                                    v-model.number="productFormula.block_group"
                                    placeholder="请输入内容"
                                    :disabled="isReadOnly || editReadOnly"
                                ></el-input>
                            </td>
                            <th></th>
                            <td></td>
                        </tr>
                        <tr>
                            <th>备注:</th>
                            <td colspan="5">
                                <el-input
                                    placeholder="备注"
                                    disabled
                                    v-model="productFormula.remarks"
                                    style="width: 100%"
                                >
                                </el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="content_r el_right">
                <div>
                    <h4 class="head el_left">
                        发送到机组
                    </h4>
                    <div class="el_right">
                        <el-button
                            type="primary"
                            size="mini"
                            class="send_out"
                            @click="openApprovalComments"
                            v-if="isShowOpinion"
                        >
                            意见
                        </el-button>
                        <el-button
                            type="primary"
                            size="mini"
                            class="send_out"
                            @click="sendOut"
                            v-if="isReadOnly==false"
                            :disabled="editReadOnly"
                        >
                            发送
                        </el-button>
                    </div>
                </div>
                <div>
                    <el-table
                        border
                        :data="mixstationlineData"
                        style="width: 100%;font-size:0.14rem;"
                        :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
                    >
                        <el-table-column
                            prop="line_name"
                            label="生产机组"
                        >
                        </el-table-column>
                        <el-table-column
                            label="砼配比编号"
                            prop="product_formula_number_hjt"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            label="润泵砂浆编号"
                            prop="product_formula_number_sj"
                            show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                            label="是否关联"
                        >
                            <template slot-scope="scope">
                                <span v-if=" scope.row.product_formula_number_hjt">
                                    <i class="iconfont iconxuanzhong" style="color:#1577D2;font-weight:800;font-size:22px"></i>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="是否发送"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.product_formula_number_hjt === null"></div>
                                <div v-else>
                                    <span v-if="scope.row.sync_status">
                                        <i class="iconfont iconxuanzhong" style="color:#1577D2;"></i>
                                    </span>
                                    <span v-else>
                                        <input
                                            class="sync_status"
                                            type="checkbox"
                                            :id="scope.$index"
                                            :value="scope.row.line_code"
                                            v-model="syncStatus"
                                            :disabled="isReadOnly || editReadOnly"
                                        >
                                        <label :for="scope.$index">
                                            <i class="iconfont icondanxuanweixuan"></i>
                                        </label>
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="content_b">
            <div class="headBox">
                <div class="mixtureRatio_info el_left">
                    <h4 class="head">
                        关联配比
                    </h4>
                    <div class="testMixBox">
                        <span class="experimentalRatioNo">试验配比名称：{{ productFormulaCount.experiment_formula_name }}</span>
                        <span class="experimentalRatioNo">试验配比编号：{{ productFormulaCount.line_experiment_formula_number }}</span>
                    </div>
                </div>
                <div class="btn_wrapper">
                    <el-button
                        type="primary"
                        @click="handelDelete"
                        v-if="isReadOnly==false"
                        :disabled="editReadOnly"
                    >
                        删除
                    </el-button>
                    <el-button
                        type="primary"
                        @click="proportioningSynchronization"
                        v-if="isReadOnly==false"
                        :disabled="editReadOnly"
                    >
                        配比同步
                    </el-button>
                    <el-button
                        type="primary"
                        @click="Choice"
                        v-if="isReadOnly==false"
                        :disabled="editReadOnly"
                    >
                        试验配比
                    </el-button>
                    <el-button
                        type="primary"
                        :disabled="(!mixstationChecked && formulaTypeChecked !== null) || editReadOnly"
                        v-if="isReadOnly==false"
                        @click="saveLine"
                    >
                        保存
                    </el-button>
                </div>
            </div>
            <div class="main_header">
                <div>
                    <div class="production_wrapper">
                        <span class="production_span">生产机组：</span>
                        <div class="radio_list">
                            <el-radio-group v-model="mixstationChecked" @change="mixstationChange">
                                <el-radio
                                    border
                                    v-for="item in mixstationlineData"
                                    :key="item.line_code"
                                    :label="item.line_code"
                                    :class="lineClass(item.is_adjust_stock)"
                                >
                                    {{ item.line_name }}
                                    <i class="icon toSave" title="已保存" v-if="item.product_formula_number_hjt && !item.sync_status"></i>
                                    <i class="icon toSend" title="已发送" v-if="item.sync_status"></i>
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div class="matching_type_wrapper">
                        <span>配比类型：</span>
                        <div class="radio_list">
                            <el-radio-group v-model="formulaTypeVal" @change="mixstationChange">
                                <el-radio
                                    v-for="item in formulaTypeData"
                                    :label="item.value"
                                    :key="item.value"
                                >
                                    {{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div>
                    <div class="statisticalValue">
                        <ul>
                            <li class="sp1">
                                <span>设计容重</span>
                                <p>{{ productFormulaCount.design_volume_weight }}<i>kg</i></p>
                            </li>
                            <li class="sp2">
                                <span>实际容重</span>
                                <p>{{ productFormulaCount.practical_volume_weight }}<i>kg</i></p>
                            </li>
                            <li class="sp3">
                                <span style="width:0.6rem;">坍落度</span>
                                <p>{{ productFormulaCount.slump }}<i>mm</i></p>
                            </li>
                            <li class="sp4">
                                <span>水胶比</span>
                                <p>{{ productFormulaCount.water_binder_ratio }}</p>
                            </li>
                            <li class="sp5">
                                <span>砂率</span>
                                <p>{{ productFormulaCount.sand_rate }}<i>%</i></p>
                            </li>
                            <li class="sp6">
                                <span>最大粒径</span>
                                <p>{{ productFormulaCount.max_particle_size }}<i>mm</i></p>
                            </li>
                            <li class="sp7">
                                <span>胶凝材料</span>
                                <p>{{ productFormulaCount.cementing_material }}</p>
                            </li>
                            <li class="sp8">
                                <span>外加剂掺量</span>
                                <p>{{ productFormulaCount.admixture_dosage }}<i>%</i></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="content_table">
                <div class="table_header">
                    <table>
                        <thead>
                            <tr>
                                <th style="width:1rem;">
                                    工控编号
                                </th>
                                <th style="width:1rem;">
                                    工控料仓类型
                                </th>
                                <th style="width:1.1rem;">
                                    原料分类
                                </th>
                                <th style="width:1.1rem;">
                                    原料子类
                                </th>
                                <th style="width:1.1rem;">
                                    原料规格
                                </th>
                                <th style="width:1rem;">
                                    试验配比用量
                                    (kg)
                                </th>
                                <th style="width:1rem;">
                                    施工配比用量
                                    (kg)
                                </th>
                                <th style="width:1rem;">
                                    含水率(%)
                                </th>
                                <th></th>
                                <th style="width:1rem;">
                                    原料分类
                                </th>
                                <th style="width:1rem;">
                                    原料子类
                                </th>
                                <th style="width:1rem;">
                                    原料规格
                                </th>
                                <th style="width:1rem;">
                                    差值
                                </th>
                                <th style="width:1rem;">
                                    施工配比用量
                                    (kg)
                                </th>
                                <th style="width:1rem;">
                                    理论配比用量
                                    (kg)
                                </th>
                                <th style="width:0.9rem;">
                                    最小值(kg)
                                </th>
                                <th style="width:0.9rem;">
                                    最大值(kg)
                                </th>
                                <th style="width:10px;"></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="table_body">
                    <table>
                        <tbody>
                            <template v-for="(item,index) in tableData">
                                <tr :key="index">
                                    <td style="width:8.3rem;" colspan="8">
                                        <table>
                                            <tbody>
                                                <tbody>
                                                    <template v-for="(k,i) in item.constructionRatio">
                                                        <tr :key="i">
                                                            <td style="width:1rem;" :title="k.plcw">
                                                                <p style="width:1rem;">
                                                                    {{ k.plcw }}
                                                                </p>
                                                            </td>
                                                            <td
                                                                style="width:1rem; color: blue; text-decoration: underline;cursor: pointer"
                                                                :title="k.plcwname"
                                                                @click="JumpChange(k)"
                                                            >
                                                                {{ k.plcwname }}
                                                            </td>
                                                            <td style="width:1.1rem;" title="原料分类">
                                                                {{ k.stock_category_name }}
                                                            </td>
                                                            <td style="width:1.1rem;" title="原料子类">
                                                                {{ k.material_child_name }}
                                                            </td>
                                                            <td style="width:1.1rem;" title="原料规格">
                                                                <el-input
                                                                    placeholder="请选择规格"
                                                                    v-model="k.material_name"
                                                                    readonly
                                                                    @focus="materialChildSelect(index, i, k)"
                                                                    :class="{ 'active-red': !k.material_code }"
                                                                >
                                                                </el-input>
                                                            </td>
                                                            <td style="width:1rem;" title="试验配比用量(kg)">
                                                                {{ k.experiment_quantity }}
                                                            </td>
                                                            <td style="width:1rem;" title="施工配比用量(kg)">
                                                                <el-input
                                                                    v-model="k.product_quantity"
                                                                    placeholder="请输入"
                                                                    min="0"
                                                                    type="number"
                                                                    :disabled="isReadOnly || editReadOnly"
                                                                    @mousewheel.native.prevent
                                                                    @change="calculation_lin(item)"
                                                                >
                                                                </el-input>
                                                            </td>
                                                            <td style="width:1rem;" title="含水率(%)">
                                                                <el-input
                                                                    min="0"
                                                                    type="number"
                                                                    placeholder="请输入"
                                                                    v-model="k.moisture_ratio"
                                                                    v-if="k.stock_category == 'F3-01-XN-01'"
                                                                    :disabled="isReadOnly || editReadOnly"
                                                                    @mousewheel.native.prevent
                                                                    @change="moistureContentChange(k)"
                                                                >
                                                                </el-input>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td></td>
                                    <td style="width:1rem;" title="原料分类">
                                        {{ item.stock_category_name }}
                                    </td>
                                    <td style="width:1rem;" title="原料子类">
                                        {{ item.material_child_name }}
                                    </td>
                                    <td style="width:1rem;" title="原料规格">
                                        {{ item.material_name }}
                                    </td>
                                    <td
                                        title="差值"
                                        style="width:1rem;"
                                        :class="[ item.difference_quantity < 0 ? 'red' : 'green' ]"
                                    >
                                        {{ item.difference_quantity }}
                                    </td>
                                    <td
                                        title="施工配比用量（kg）"
                                        style="width:1rem;"
                                        :class="{'overrun': item.product_quantity
                                            && item.product_quantity < item.theory_quantity_min
                                            && item.product_quantity > item.theory_quantity_max}"
                                    >
                                        <i class="iconfont iconyujing"></i>
                                        {{ item.product_quantity }}
                                    </td>
                                    <td style="width:1rem;" title="理论配比用量(kg)">
                                        {{ item.theory_quantity }}
                                    </td>
                                    <td style="width:0.9rem;" title="最小值(kg)">
                                        {{ item.theory_quantity_min }}
                                    </td>
                                    <td style="width:0.9rem;" title="最大值(kg)">
                                        {{ item.theory_quantity_max }}
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            width="40%"
            :modal="false"
            :close-on-click-modal="false"
            center
            @close="closeDialogChange"
        >
            <el-table
                :data="materialModelSelectData"
                border
                height="3rem"
                style="width: 100%;"
                :header-cell-style="{ height: '0.34rem', background: 'rgba(237,240,245,1)', color: '#022782', padding: '0'}"
            >
                <el-table-column label="选择" width="60">
                    <template slot-scope="scope">
                        <input
                            type="radio"
                            name="name"
                            v-model="materialModelValue"
                            :value="scope.row.material_name"
                        >
                    </template>
                </el-table-column>
                <el-table-column prop="material_name" label="名称"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="materialChildSelectChange">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            width="30%"
            :visible.sync="isShowApprovalComments"
            append-to-body
            custom-class="opinionListDialog"
        >
            <div class="opinionListContent">
                <h4>审批意见列表</h4>
                <ul>
                    <template v-for="(item, index) in approvalCommentsList">
                        <li :key="`approvalComments_${index}`">
                            <h5>{{ item.line_name }}</h5>
                            <p>{{ item.approval_opinion }}</p>
                            <div class="essentialInfo">
                                <span class="iconfont iconyewuyuan el_left">审批人：{{ item.send_user_name }}</span>
                                <span class="el_right">{{ item.approval_time }}</span>
                            </div>
                            <div
                                class="cornerMarker"
                                :class="{
                                    'pendingTrial': item.approval_status == 0,
                                    'adopt': item.approval_status == 1,
                                    'noAdopt': item.approval_status == 2
                                }"
                            ></div>
                        </li>
                    </template>
                </ul>
            </div>
        </el-dialog>
        <el-dialog
            title="配比同步页面"
            :visible.sync="synchronizationShow"
            append-to-body
            width="6rem"
            custom-class="synchronizationDialog"
        >
            <div class="content">
                <div class="checkboxHeader">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                        全选
                    </el-checkbox>
                    <p class="iconfont iconweizhi">
                        当前机组：{{ mixstationName }}
                    </p>
                </div>
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <template v-for="(item, index) in synchronousUnitData">
                        <el-checkbox :label="item" :key="`synchronousUnit_${index}`">
                            <div class="checkboxLabel">
                                <span>{{ item.sync_line_name }}</span>
                                <p>
                                    允许同步：
                                    <el-tag type="success" v-if="item.is_can_sync">
                                        是
                                    </el-tag>
                                    <el-tag type="success" v-else>
                                        否
                                    </el-tag>
                                </p>
                            </div>
                            <p class="iconfont icontishi">
                                <span v-if="item.source_goods_warning">{{ item.source_goods_warning }}</span>
                                <i v-if="item.source_goods_warning && item.sync_warning_reason">,</i>
                                <span v-if="item.sync_warning_reason">{{ item.sync_warning_reason }}</span>
                            </p>
                        </el-checkbox>
                    </template>
                </el-checkbox-group>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="synchronizationShow = false">
                    关 闭
                </el-button>
                <el-button type="primary" @click="synchronizationConfirm">
                    确 认
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import store from '@/store';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            // 施工配比基本信息存储
            productFormula: {
                project_name: '',
                project_detail: '',
                is_datum: false,
                is_testblock: false,
            },
            // 全部生产机组
            mixstationlineData: [],
            // 机组选中项code
            mixstationChecked: null,
            // 机组选中项名称
            mixstationName: '',
            // 全部配比类型
            formulaTypeData: [
                {
                    label: '砼配比',
                    value: 0,
                },
                {
                    label: '润泵砂浆配比',
                    value: 1,
                },
                {
                    label: '润泵砂浆配比（标号）',
                    value: 2,
                },
            ],
            // 配比类型绑定字段
            formulaTypeVal: 0,
            // 配比类型选中项
            formulaTypeChecked: 0,
            // 列表数据
            tableData: [],
            // 线路配比数据
            productFormulaLine: {},
            // 色块字段取值
            productFormulaCount: {
                design_volume_weight: 0,
                practical_volume_weight: 0,
                slump: 0,
                water_binder_ratio: 0,
                sand_rate: 0,
                max_particle_size: 0,
            },
            // 需要发送的机组
            syncStatus: [],
            // 任务单号
            task_number: '',
            // 页面加载状态
            isLoading: false,

            // 查看只读（隐藏所有操作按钮）
            isReadOnly: false,
            // 编辑只读（显示所有操作按钮，没有操作权限）
            editReadOnly: false,

            // 原材料规格弹出层状态
            dialogVisible: false,
            // 原材料规格下拉列表数据
            materialModelSelectData: [],
            // 原材料规格弹框选中值
            materialModelValue: '',
            tableIndex: 0,
            tableI: 0,
            // 审批意见按钮权限
            isShowOpinion: false,
            // 审批意见弹框
            isShowApprovalComments: false,
            // 审批意见数据
            approvalCommentsList: [],

            // 配比同步
            synchronousUnitData: [],
            checkedCities: [],
            synchronizationShow: false,
            checkAll: false,
            isIndeterminate: true,
        };
    },
    created() {},
    mounted() {
        if (this.extr && this.extr.taskNumber) {
            if (this.extr.isReadOnly) {
                this.isReadOnly = this.extr.isReadOnly;
            }
            this.task_number = this.extr.taskNumber;
            // 初始化页面数据
            this.getInit();
        }
    },
    watch: {
        formulaTypeVal(newVal) {
            if (newVal === 0) {
                this.formulaTypeChecked = 0;
            } else if ([1, 2].indexOf(newVal) > -1) {
                this.formulaTypeChecked = 1;
            }
        },
    },
    methods: {
        // 初始化页面数据
        getInit() {
            this.$axios
                .get('/interfaceApi/production/productformula/formulawithline/' + this.extr.taskNumber)
                .then(res => {
                    if (res.productFormula.is_approval_formula) {
                        this.isShowOpinion = true;
                    }
                    if (res.productFormula.formula_approval_status === 0) {
                        this.editReadOnly = true;
                    }
                    this.productFormula = res.productFormula;
                    this.productFormula.mark_number = this.productFormula.strength_grade_name + this.productFormula.special_require_name;
                    this.mixstationlineData = res.productFormulaLine;
                    this.productFormulaLine = res.productFormulaLine;
                    if (this.mixstationlineData.length > 0) {
                        if (!this.mixstationChecked) {
                            this.mixstationChecked = this.mixstationlineData[0].line_code;
                            this.mixstationName = this.mixstationlineData[0].line_name;
                        }
                        this.mixstationChange();
                    }

                    this.syncStatus = [];
                    if (res.productFormulaLine.length > 0) {
                        res.productFormulaLine.forEach(item => {
                            if (item.sync_status) {
                                this.syncStatus.push(item.line_code);
                            }
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 打开审批意见
        openApprovalComments() {
            this.$axios
                .get(`/interfaceApi/production/productformula/formula_approval/list/${this.extr.taskNumber}`)
                .then(res => {
                    if ((res || []).length > 0) {
                        this.approvalCommentsList = res;
                        this.isShowApprovalComments = true;
                    } else {
                        this.$message({
                            message: '暂无审批意见！',
                            type: 'warning',
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 发送
        sendOut() {
            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/productformula/send',
                    {
                        task_number: this.extr.taskNumber,
                        send_line_code: this.syncStatus,
                    }
                )
                .then(res => {
                    if (res && res.is_control) {
                        let text = '管控提醒';
                        if (res.msg_title) {
                            text = res.msg_title;
                        }
                        this.$alert(`${res.control_msg}`, text, {
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            confirmButtonText: '确定',
                        });
                    }
                    this.$message.success('发送成功');
                    this.getInit();
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换生产机组
        mixstationChange() {
            this.tableData = [];
            this.productFormulaCount = {};
            this.mixstationName = this.mixstationlineData.filter(item => item.line_code === this.mixstationChecked)[0].line_name;
            const tableData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });
            if (tableData.length > 0) {
                if (this.formulaTypeChecked === 0) {
                    if (tableData[0].product_formula_hjt) {
                        this.processingData(tableData[0].product_formula_hjt);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                } else if (this.formulaTypeChecked === 1) {
                    if (tableData[0].product_formula_sj) {
                        this.processingData(tableData[0].product_formula_sj);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                }
            } else {
                this.tableData = [];
                this.productFormulaCount = {};
            }
        },
        // 删除生产线配比
        handelDelete() {
            this.$confirm('确认删除当前生产线配比?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.Loading = true;
                const url = '/interfaceApi/production/productformula/del_formula_line/mixp_roportion'
                    + `?taskNumber=${this.task_number}&lineCode=${this.mixstationChecked}`;
                this.$axios.put(url)
                    .then(() => {
                        this.getInit();
                        setTimeout(() => {
                            this.mixstationChange();
                        }, 300);
                        this.Loading = false;
                        this.$message('操作成功');
                    }).catch(error => {
                        this.Loading = false;
                        this.$message.error(error.ErrorCode.Message);
                    });
            });
        },

        // 配比同步
        async proportioningSynchronization() {
            this.synchronousUnitData = await this.getSynchronousUnit(this.mixstationChecked);
            this.synchronizationShow = true;
            this.checkAll = false;
            this.checkedCities = [];
        },

        // 全选
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.synchronousUnitData : [];
            this.isIndeterminate = false;
        },
        // 单选
        handleCheckedCitiesChange(value) {
            const checkedCount = value.length;
            this.checkAll = checkedCount === this.synchronousUnitData.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.synchronousUnitData.length;
        },
        // 同步确认
        synchronizationConfirm() {
            if ((this.checkedCities || []).length < 1) {
                this.$message({
                    message: '请先选择需要同步的生产机组！',
                    type: 'warning',
                });
            } else {
                const msgList = [];
                const newDom = this.$createElement;
                this.checkedCities.forEach(item => {
                    let newText = '';
                    if (item.source_goods_warning) {
                        newText += item.source_goods_warning;
                        if (item.source_goods_warning && item.sync_warning_reason) {
                            newText += newText + ',';
                        }
                    } else if (item.sync_warning_reason) {
                        newText += item.sync_warning_reason;
                    }
                    msgList.push(

                        newDom('p', null, [
                            newDom('span', null, `${item.sync_line_name}  `),
                            newDom('i', { style: 'color: #E02020' }, `${newText}。`),
                        ]),
                    );
                });
                this.$msgbox({
                    title: '同步确认',
                    message: newDom('div', null, [
                        ...msgList,
                        newDom('p', null, [
                            newDom('span', null, '是否继续同步？'),
                        ]),
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(async () => {
                    const parameter = {
                        product_formula_number: this.productFormulaCount.product_formula_number,
                        syncLineCode: [],
                    };
                    parameter.syncLineCode = this.checkedCities.map(item => item.sync_line_code);
                    const synchronousReturn = await this.synchronousUnit(parameter);
                    if (synchronousReturn) {
                        this.$message({
                            message: '同步成功！',
                            type: 'success',
                        });
                    }
                    this.synchronizationShow = false;
                    this.checkAll = false;
                    this.checkedCities = [];
                    // 初始化页面数据
                    this.getInit();
                });
            }
        },

        // 选择实验配比
        Choice() {
            const newCode = {
                TableCode: 'choose_experiment_formula',
                QueryString: 'line_code='
                            + this.mixstationChecked + '&formula_type='
                            + this.formulaTypeChecked + '&strength_grade='
                            + this.productFormula.strength_grade + '&station_id='
                            + this.productFormula.station_id,
            };
            if (this.formulaTypeVal === 2) {
                newCode.QueryString = newCode.QueryString + '&is_filter_mark=1';
            }
            this.$toast(
                {
                    title: true,
                    text: '选择实验配比',
                    type: 'eject',
                    t_url: 'tableList/index',
                    extr: {
                        code: newCode,
                        sureCallback: this.selectCallback,
                    },
                }
            );
        },
        // 选择实验配比回调
        selectCallback(table) {
            this.$axios
                .get('/interfaceApi/production/experimentformula/choose_experiment_formula/'
                    + `${this.mixstationChecked}/${table.experiment_formula_number}/${table.versions}`)
                .then(res => {
                    // 数据处理
                    this.processingData(res);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据处理
        processingData(res) {
            let newData = [];
            const data = JSON.parse(JSON.stringify(res));
            this.productFormulaCount = JSON.parse(JSON.stringify(data));

            data.productFormulaRelExperimentOutputs.forEach(z => {
                z.constructionRatio = [];
            });

            const correlationRatio = [];
            data.productFormulaEntityOutputs.forEach(k => {
                data.productFormulaRelExperimentOutputs.forEach(item => {
                    if (item.material_code !== ''
                        && k.material_code === item.material_code
                        && (item.material_bg_code !== '' && k.material_bg_code === item.material_bg_code || (item.material_bg_code || '') === '' )
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && item.material_child_name !== ''
                        && k.material_child_name === item.material_child_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && (item.material_child_name === '' || k.material_child_name === '' )
                        && item.stock_category_name !== ''
                        && k.stock_category_name === item.stock_category_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    }
                });
            });
            // eslint-disable-next-line max-len
            const row = data.productFormulaEntityOutputs.filter(item => !correlationRatio.some(ele => ele.material_code === item.material_code && ele.material_child_name === item.material_child_name && ele.stock_category_name === item.stock_category_name));
            // 有工控仓位没有试验配比，试验配比新增一条数据，把工控仓位合并到试验配比数据里
            const unmatchedData = [];

            const result = row.reduce((a, b) => {
                const codeName = `${b.material_code}_${b.material_child_name}_${b.stock_category_name}`;
                if (a[codeName]) {
                    a[codeName].push(b);
                } else {
                    a[codeName] = [b];
                }
                return a;
            }, {});

            for (const item in result) {
                unmatchedData.push({
                    constructionRatio: [...result[item]],
                });
            }

            newData = [...data.productFormulaRelExperimentOutputs, ...unmatchedData];

            newData.forEach(item => {
                if (item.constructionRatio.length > 0) {
                    item.sort = item.constructionRatio[0].plcw;
                } else {
                    item.sort = '999';
                }
            });

            newData.sort(function (a, b) {
                return a.sort - b.sort;
            });

            this.tableData = newData;

            this.calculation_BasicInfoValue();
        },
        // 工控料仓类型弹出
        JumpChange(row) {
            this.$toast(
                {
                    title: true,
                    text: '工控与ERP关系配置',
                    type: 'eject',
                    width: '13rem',
                    height: 'auto',
                    t_url: 'purchaseManage/centralControlConfig',
                    extr: {
                        mixstation_code: this.$takeTokenParameters('StationId'),
                        line_code: this.mixstationChecked,
                        plcw: row.plcw,
                        scene: 'view',
                    },
                }
            );
        },
        // 选择原材料规格弹出
        materialChildSelect(index, i, data) {
            if (data.stock_category_dictcode === '203' || data.stock_category_dictcode) {return;}
            this.tableIndex = index;
            this.tableI = i;
            this.dialogVisible = true;
            this.$axios
                .get('/interfaceApi/Procurement/stock/materials/stock_code/' + data.code)
                .then(res => {
                    this.materialModelSelectData = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 确认选择原材料规格弹出
        materialChildSelectChange() {
            if (this.materialModelValue) {
                this.dialogVisible = false;
                this.tableData[this.tableIndex].constructionRatio[this.tableI].material_name = this.materialChildValue;
                const material_child_dictcode = this.materialChildSelectData.filter(
                    item => item.material_name === this.materialChildValue)[0].material_code;
                this.tableData[this.tableIndex].constructionRatio[this.tableI].material_code = material_child_dictcode;
            } else {
                this.$message.error('请至少选择一个');
            }
        },
        // 弹窗关闭的回调方法
        closeDialogChange() {
            // 原材料规格下拉列表数据
            this.materialModelSelectData = [];
            // 原材料规格弹框选中值
            this.materialModelValue = '';
        },
        // 保存
        saveLine() {
            const productFormulaLine = {
                product_formula_number: this.productFormulaCount.product_formula_number || '', // 施工配比编号
                line_experiment_formula_number: this.productFormulaCount.line_experiment_formula_number || '', // 生产线实验配比编号
                experiment_formula_name: this.productFormulaCount.experiment_formula_name,
                task_number: this.extr.taskNumber, // 任务单号
                formula_type: this.formulaTypeChecked, // 配方类型
                line_code: this.mixstationChecked, // 生产线编码
                design_volume_weight: this.productFormulaCount.design_volume_weight || '', // 设计容重
                slump: this.productFormulaCount.slump || '', // 坍落度
                water_binder_ratio: this.productFormulaCount.water_binder_ratio || '', // 水胶比
                sand_rate: this.productFormulaCount.sand_rate || '', // 砂率
                max_particle_size: this.productFormulaCount.max_particle_size || '', // 最大粒径
                practical_volume_weight: this.productFormulaCount.practical_volume_weight, // 实际容重
            };
            // 获取当前选中生产线
            const newLin = this.mixstationlineData.filter(item => {
                return item.line_code === this.mixstationChecked;
            })[0];
            // 生产线名称
            productFormulaLine.line_name = newLin.line_name;
            // 生产拌台
            productFormulaLine.scbt = newLin.scbt;
            // 排序字段
            productFormulaLine.sort_num = newLin.sort_num;

            const productFormulaEntity = [];
            this.tableData.forEach(item => {
                productFormulaEntity.push(...item.constructionRatio);
            });
            productFormulaEntity.forEach((item, index) => {
                item.sort_num = index;
            });
            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/productformula/save_line',
                    {
                        productFormulaLine: productFormulaLine,
                        productFormulaEntity: productFormulaEntity,
                        productFormulaRelExperiments: this.tableData,
                    })
                .then(res => {
                    if (res.is_control ) {
                        this.$alert(`${res.control_msg}`, '管控提醒', {
                            dangerouslyUseHTMLString: true,
                            type: 'warning',
                            confirmButtonText: '确定',
                        });
                    }
                    this.$message.success('保存成功');
                    this.getInit();
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存施工配比基本信息
        saveProductFormula() {
            this.isLoading = true;
            this.$axios
                .post('/interfaceApi/production/productformula/save', this.productFormula)
                .then(() => {
                    this.$message.success('保存成功');
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    this.isLoading = false;
                })
                .catch(error => {
                    this.isLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 生产机组类名
        lineClass(val) {
            if (val) {
                return 'red-class';
            }
            return '';
        },
        // 计算当前行实验配比和差值
        calculation_lin(data) {
            let nub = 0;
            data.constructionRatio.forEach(item => {
                nub += Number(item.product_quantity) * 10000;
            });
            data.product_quantity = nub / 10000;
            if (data.theory_quantity && data.product_quantity) {
                data.difference_quantity = (Number(data.theory_quantity) * 10000 - Number(data.product_quantity) * 10000) / 10000;
            }
            // 计算基本信息（容重、水胶比、砂率、外加剂掺量、胶凝材料）
            this.calculation_BasicInfoValue();
            this.$forceUpdate();
        },
        // 含水率输入
        moistureContentChange(item) {
            if ((item.moisture_ratio || 0) > 0 && (item.experiment_quantity || 0) > 0) {
                item.product_quantity = NP.times(item.experiment_quantity || 0, NP.plus(1, NP.divide(item.moisture_ratio, 100)));
            }
            const row = [];
            this.tableData.forEach(item => {
                row.push(...item.constructionRatio);
            });
            // 细骨料含水量
            let waters = 0;
            // 水
            const waterList = row.filter(item => item.stock_category === 'F3-01-XN-03');
            // 细骨料
            const fineAggregateList = row.filter(item => item.stock_category === 'F3-01-XN-01');
            if (fineAggregateList.length > 0) {
                fineAggregateList.forEach(z => {
                    waters += NP.times(z.experiment_quantity || 0, NP.divide(z.moisture_ratio || 0, 100));
                });
            }
            if (waterList.length > 0) {
                waterList[0].product_quantity = NP.minus(waterList[0].experiment_quantity || 0, waters);
            }
        },

        // 计算基本信息（容重、水胶比、砂率、外加剂掺量、胶凝材料）
        calculation_BasicInfoValue() {
            let practical_volume_weight = 0; // 容重
            let fineAggregate = 0; // 细骨料
            let coarseAggregate = 0; // 粗骨料
            let water = 0; // 水
            let powder = 0; // 粉料
            let cement = 0; // 水泥
            let admixtures = 0; // 掺合料（分类，字典值205-开头的）
            let powderyExpansiveAgent = 0; // 粉状膨胀剂（规格：外加剂-膨胀剂-粉状膨胀剂）
            let admixture = 0; // 外加剂(排除粉状膨胀剂)

            const rowData = [];
            this.tableData.forEach(item => {
                rowData.push(...item.constructionRatio);
            });
            rowData.forEach(k => {
                practical_volume_weight += Number(Number(k.product_quantity || 0).toFixed(2)) * 100;
                if (k.stock_category_dictcode === '203') {
                    fineAggregate += Number(k.product_quantity);
                }
                if (k.stock_category_dictcode === '204') {
                    coarseAggregate += Number(k.product_quantity);
                }
                if (k.stock_category_dictcode === '207') {
                    water += Number(k.product_quantity);
                }
                // eslint-disable-next-line max-len
                if (['202', '205-FHL', '205-GHJ', '205-FMH', '205-GH0', '205-GZF', '205-KZF', '205-LZF', '205-SHF'].indexOf(k.stock_category_dictcode) > -1) {
                    powder += Number(k.product_quantity);
                }
                if (k.stock_category_dictcode === '202') {
                    cement = NP.plus(cement, k.product_quantity || 0);
                }

                if (k.stock_category_dictcode.indexOf('205-') > -1) {
                    admixtures = NP.plus(admixtures, k.product_quantity || 0);
                }

                if (k.stock_category_dictcode === '208') {
                    if (k.material_child_dictcode === '208-PZJ' && k.material_code === '208-PZJ-01') {
                        powderyExpansiveAgent = k.product_quantity;
                    }
                    if (k.material_code !== '208-PZJ-01') {
                        admixture = NP.plus(admixture, k.product_quantity || 0);
                    }
                }

            });
            // 砂率
            if (fineAggregate > 0) {
                // eslint-disable-next-line max-len
                this.productFormulaCount.sand_rate = Number(NP.times(NP.divide(fineAggregate, NP.plus(fineAggregate, coarseAggregate)), 100)).toFixed(2);
            } else {
                this.productFormulaCount.sand_rate = 0;
            }

            // 水胶比
            if (water > 0 && powder > 0) {
                this.productFormulaCount.water_binder_ratio = NP.divide(water, powder).toFixed(2);
            } else {
                this.productFormulaCount.water_binder_ratio = 0;
            }

            // 容重
            this.productFormulaCount.practical_volume_weight = Number(NP.divide(practical_volume_weight, 100)).toFixed(2);

            // 胶凝材料
            this.productFormulaCount.cementing_material = Number(NP.plus(cement, admixtures, powderyExpansiveAgent));

            // 外加剂掺量
            if (this.productFormulaCount.cementing_material) {
                // eslint-disable-next-line max-len
                this.productFormulaCount.admixture_dosage = Number(NP.times(NP.divide(admixture, this.productFormulaCount.cementing_material), 100)).toFixed(2);
            } else if (admixture > 0) {
                this.productFormulaCount.admixture_dosage = 100;
            } else {
                this.productFormulaCount.admixture_dosage = 0;
            }
        },


        // 获取待同步机组
        getSynchronousUnit(lineCode) {
            return this.$axios.$get(`/interfaceApi/production/productformula/save_line/query_sync_line?line_code=${lineCode}`, { defEx: true });
        },

        // 同步生产线施工配比到其他生产线
        synchronousUnit(data) {
            return this.$axios.$post('/interfaceApi/production/productformula/save_line/sync_new', data, { defEx: true });
        },
    },
};
</script>

<style lang="stylus">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none;
input[type="number"]
    -moz-appearance: textfield;
.construction_matching
    width 100%
    height 100%
    background #e0e5eb
    position relative
    .content_t
        height 3rem
        width 100%
        overflow hidden
        margin-bottom 0.2rem
        .header_wrapper
            padding 0.2rem 0.2rem 0.1rem 0.2rem
            width 11.4rem
            height 3rem !important
            background #ffffff
            position relative
            .save
                width 0.6rem
                height 0.34rem
                padding 0
                position absolute
                right 0.2rem
                top 0.2rem
                font-size 0.16rem
            table
                width 100%
                height calc(100% - 0.4rem)
                th
                    font-size 0.14rem
                    color #333
                    font-weight 400
                    text-align left
                    padding-left 0.1rem
                td
                    padding-right 0.1rem
                    &:last-child
                        padding-right 0
                    .el-radio
                        &::after
                            transition none !important
                    .el-input__inner
                        color #000
        .content_r
            width 5.4rem !important
            height 100%
            background #ffffff
            padding 0.2rem
            position relative
            .send_out
                width 0.6rem
                height 0.34rem
                font-size 0.16rem
                padding 0
            .el-table
                width 5rem
                height 2.2rem !important
                font-size 0.16rem
                .el-table__header-wrapper
                    width 5rem
                    .el-table__header
                        width 5rem !important
                        table-layout inherit
                        th
                            .cell
                                font-size 0.14rem
                                padding 0
                                text-align center
                .el-table__body-wrapper
                    width 5rem
                    height calc(100% - 0.34rem)
                    overflow-y auto
                    .el-table__body
                        width 5rem !important
                        table-layout inherit
                        .el-table__row
                            td
                                height 0.26rem
                                padding 0
                                line-height 0.26rem
                                .cell
                                    padding 0
                                    text-align center
                                    .sync_status
                                        display none
                                        opacity 0
                                        cursor:pointer
                                        &:checked+label>i
                                            color #1577D2
                                            font-weight 800
                                            font-size 0.22rem
                                        &:checked+label>i::before
                                            content: "\e6c1";
                                    label
                                        width 100%
                                        height 100%
                                        display block
                                        cursor:pointer
                                    .iconfont
                                        font-size 0.22rem
                                        line-height 0.22rem
    .content_b
        width 100%
        height calc(100% - 3.2rem)
        background #ffffff
        padding 0.2rem
        overflow hidden
        .headBox
            overflow hidden
            .mixtureRatio_info
                display flex
                .head
                    margin-bottom 0.15rem
                .experimentalRatioNo
                    margin-left 0.5rem
                    font-size 0.16rem
                    line-height 0.2rem
        .btn_wrapper
            float right
            .el-button
                height 0.34rem
                padding 0rem 0.1rem
                font-size 0.16rem
        .main_header
            margin-top 0.1rem
            display flex
            justify-content space-between
            .production_wrapper
                &::after
                    content ''
                    height 0
                    line-height 0
                    display block
                    visibility hidden
                    clear both
                .production_span
                    font-size 0.16rem
                    float left
                    line-height 0.34rem
                    width 0.9rem
                .radio_list
                    float left
                    width calc(100% - 0.9rem)
                    .red-class
                        border 0.01rem solid red
                        .el-radio__label
                            color red
                    .el-radio
                        height 0.34rem
                        line-height 0.34rem
                        margin-right: 0 !important;
                        margin-bottom 0.1rem
                        position relative
                        padding 0 0.15rem !important
                        line-height 0.34rem
                        overflow initial
                        &:nth-child(8n+0)
                            margin-left 0
                        .el-radio__input
                            display none
                        .el-radio__label
                            padding 0
                            width 100%
                            height 100%
                            line-height 0.1rem
                        i
                            position absolute
                            top -0.1rem
                            right -0.1rem
                            width 0.2rem
                            height 0.2rem
                            &.toSave
                                background url(./images/save@2x.png) no-repeat
                                background-size 100% 100%
                            &.toSend
                                background url(./images/send@2x.png) no-repeat
                                background-size 100% 100%
                    .is-bordered
                        padding 0.1rem
            .matching_type_wrapper
                display flex
                span
                    font-size 0.16rem
                    line-height 0.34rem
            .statisticalValue
                ul
                    width 7.9rem
                    overflow hidden
                    li
                        width 1.9rem
                        height 0.3rem
                        border 1px solid #D7D7D7
                        float left
                        margin-right 0.1rem
                        margin-bottom 0.1rem
                        &:nth-of-type(4)
                        &:nth-of-type(8)
                            margin-right 0
                        span
                            font-size 0.16rem
                            line-height 0.28rem !important
                            display block
                            height 0.28rem
                            width 0.85rem
                            float left
                            text-align center
                        p
                            float right
                            line-height 0.28rem !important
                            font-size 0.16rem
                            margin-right 0.1rem
                            i
                                font-size 0.14rem
                                color rgba(215,215,215,1)

                    .sp1
                        span
                            background rgba(85, 136, 241, 0.1)
                            color #5588F1
                        p
                            color #5588F1
                    .sp2
                        span
                            background rgba(235, 101, 111, 0.1)
                            color #EB656F
                        p
                            color #EB656F
                    .sp3
                        span
                            background rgba(31, 180, 163, 0.1)
                            color #1FB4A3
                        p
                            color #1FB4A3
                    .sp4
                        span
                            background rgba(33, 189, 204, 0.1)
                            color #21BDCC
                        p
                            color #21BDCC
                    .sp5
                        span
                            background rgba(250, 132, 54, 0.1)
                            color #FA8436
                        p
                            color #FA8436
                    .sp6
                        span
                            background rgba(139, 107, 238, 0.1)
                            color #8B6BEE
                        p
                            color #8B6BEE
                    .sp7
                        span
                            background rgba(50, 197, 255, 0.1)
                            color #32C5FF
                        p
                            color #32C5FF
                    .sp8
                        span
                            background rgba(109, 212, 0, 0.1)
                            color #6DD400
                        p
                            color #6DD400
        .content_table
            width 100%
            height 3rem
            border 1px solid #EBEEF5
            .table_header,
            .table_body
                width 100%
                table
                    table-layout fixed
                    word-break break-all
                    width 100%
                    border-collapse collapse
                    th,td
                        font-size 0.14rem
                        text-align center
                        height 0.34rem
                    th
                        background rgb(237, 240, 245)
                        color rgb(2, 39, 130)
                        border-right 1px solid #EBEEF5
                        border-bottom 1px solid #EBEEF5
                        &::last-child
                            border-right none
            .table_body
                max-height calc(100% - 0.39rem)
                overflow-y scroll
                td
                    border-right 1px solid #EBEEF5
                    border-bottom 1px solid #EBEEF5
                    &:last-child
                        border-right none
                    .el-button
                        width auto
                        height auto
                        line-height initial
                        margin 0.05rem
                        font-size 12px
                        color #409EFF
                        background none
                    >i
                        display none
                    >p
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    &.red
                        color red
                    &.green
                        color #30fa3e
                    &.overrun
                        color red
                        i
                            font-size 0.14rem
                            vertical-align top
                            display inline-block
            .active-red
                color red
    .el-dialog
        height auto !important
        .el-table__row
            th
                .cell
                    text-align center
            td
                padding 0
                height 0.34rem
                text-align center
        .el-button
            margin 0 0.4rem
        .el-dialog__body
            padding 0.15rem
    .el-dialog__wrapper
        height 100% !important
// 意见列表弹出样式
.opinionListDialog
    background rgba(255, 255, 255, 0.3)
    .el-dialog__header
        padding 0
    .el-dialog__body
        padding 0
        overflow hidden
        .opinionListContent
            margin 0.1rem
            background #fff
            padding 0.3rem
            h4
                font-size 0.2rem
                color #333333
                margin-bottom 0.15rem
            ul
                max-height 4rem
                overflow-y auto
                overflow-x hidden
                li
                    border 1px solid #E8E8E8
                    padding 0.15rem
                    position relative
                    margin-bottom 0.15rem
                    &:last-child
                        margin-bottom 0
                    h5
                        font-size 0.16rem
                        line-height 0.16rem
                        color #333333
                        margin-bottom 0.07rem
                    p
                        font-size 0.14rem
                        color #7D7D7D
                        margin-bottom 0.15rem
                    .essentialInfo
                        overflow hidden
                        span
                            color #979EA7
                            font-size 0.12rem
                    .cornerMarker
                        width 0.4rem
                        height 0.4rem
                        position absolute
                        top 0
                        right 0
                        background-repeat no-repeat
                        background-size 100%
                        &.pendingTrial
                            background-image url(./images/corner_mark_no_approval.png)
                        &.adopt
                            background-image url(./images/corner_mark_pass.png)
                        &.noAdopt
                            background-image url(./images/corner_mark_no_pass.png)
// 配比同步弹出
.synchronizationDialog
    border 0.1rem solid rgba(255, 255, 255, 0.3)
    background none
    >div
        background #fff
    .el-dialog__body
        height 5rem
        padding 0.1rem 0
        .content
            height 100%
            .checkboxHeader
                display flex
                justify-content space-between
                margin-bottom 0.1rem
                padding 0 0.2rem
                >p
                    color #326993
                    font-size 0.16rem
            .el-checkbox-group
                padding 0 0.2rem
                overflow-y auto
                height calc(100% - 0.34rem)
                .el-checkbox
                    margin-right 0
                    width 100%
                    display flex
                    padding-left 0.2rem
                    border 1px solid #D7D7D7
                    margin-bottom 0.2rem
                    &:last-child
                        margin-bottom 0
                    &.is-checked
                        border-color #1890FF
                        box-shadow 0px 2px 4px 0px rgba(0, 0, 0, 0.3)
                    .el-checkbox__input
                        line-height 0.44rem
                        .el-checkbox__inner
                            font-size 0.14rem
                    .el-checkbox__label
                        width 100%
                        font-size 0.16rem
                        line-height 0.4rem
                        padding-left 0.1rem
                        .checkboxLabel
                            padding-right 0.2rem
                            display flex
                            justify-content space-between
                            >p
                                color #979EA7
                                font-size 0.14rem
                                .el-tag
                                    height 0.2rem
                                    line-height 0.18rem
                        >p
                            background #FFFBE6
                            color #F7B500
                            font-size 0.14rem
                            line-height 0.3rem
                            margin-left -0.44rem
                            padding-left 0.2rem
                            padding-right 0.1rem
                            width calc(100% + 0.44rem)
                            white-space initial
    .el-dialog__footer
        text-align center
        .el-button
            width 1.6rem
            margin 0 0.2rem
</style>
